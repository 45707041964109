body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.terms-content,
.terms-hero,
.terms-footer {
  overflow: visible;
}

.terms-container {
  background-color: #000000;
  color: white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Telegraf", sans-serif;
  overflow: hidden;
}

.terms-hero {
  width: 100%;
  text-align: center;
  padding: 80px 20px;
}

.terms-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.terms-subtitle {
  font-size: 1.5rem;
  color: #888;
  max-width: 600px;
  margin: 0 auto;
}

.terms-content {
  width: 100%;
  max-width: 900px;
  padding: 40px 20px;
}

.terms-intro {
  opacity: 0;
  transform: scale(0.95);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  margin-bottom: 60px;
  padding: 0 20px;
}

.terms-intro h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
}

.terms-intro p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  color: #888;
  text-align: justify;
}

.terms-sections {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.terms-section {
  background: rgba(255, 255, 255, 0.02);
  padding: 32px;
  transition: all 0.3s ease;
  position: relative;
  opacity: 0;
  transform: scale(0.95);
}

.terms-section.animate {
  opacity: 1;
  transform: scale(1);
}

.terms-section:hover {
  background: rgba(255, 255, 255, 0.03);
  box-shadow: -4px 4px 12px rgba(140, 82, 255, 0.1);
  transform: scale(1.02);
}

.section-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.section-icon {
  width: 28px;
  height: 28px;
  color: #8c52ff;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.terms-section:hover .section-icon {
  transform: scale(1.1);
  color: #ff69b4;
}

.section-title {
  color: white;
  font-size: 1.35rem;
  font-weight: 500;
  margin: 0;
}

.section-content {
  color: #888;
  line-height: 1.6;
  font-size: 1rem;
  text-align: justify;
}

.terms-footer {
  margin-top: 60px;
  padding: 40px;
  background: rgba(140, 82, 255, 0.02);
  border: 1px solid rgba(140, 82, 255, 0.1);
  border-radius: 8px;
  text-align: center;
}

.footer-section {
  margin-bottom: 40px;
}

.footer-section:last-child {
  margin-bottom: 0;
}

.footer-section h2 {
  color: white;
  font-size: 1.75rem;
  margin-bottom: 16px;
}

.footer-section p {
  color: #888;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  text-align: justify;
}

.terms-copyright {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #888;
  text-align: center;
}

@media (max-width: 768px) {
  .terms-title {
    font-size: 2.5rem;
  }

  .terms-subtitle {
    font-size: 1.2rem;
  }

  .terms-section {
    padding: 24px;
  }
}

@media (max-width: 480px) {
  .terms-title {
    font-size: 2rem;
  }

  .terms-subtitle {
    font-size: 1rem;
  }

  .terms-intro h2 {
    font-size: 1.75rem;
  }
}
