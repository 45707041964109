.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 5vw;
  padding: 20px;
  padding-left: 30px; 
  margin: 0; 
  position: fixed; 
  top: 0; 
  left: 0;
  z-index: 100;
}

.no{
  color: var(--purple);
}

.login{
  color: var(--orange);
}

.navbar-title{
  font-size: 1.5rem;
  font-weight: 700;
}

.navbar-left, .navbar-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap; 
  /* max-width: 100%;  */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 49.4px; 
  height: 42.6px; 
  margin-right: 10px;
}

.navbar-left .title {
  font-size: 20px;
  color: #fff;
  font-family: 'Coco Gothic', sans-serif; 
}

.navbar-right {
  display: inline-block;
  margin: 0 5px;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px; 
  padding: 10px;
}

.nav-button {
  font-size: 15px;
  font-family: 'Telegraf', sans-serif; 
  color: rgba(255, 255, 255, 0.8);
  margin-left: 20px;
  text-decoration: none;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
  border-radius: 5px
}

.nav-button:hover {
  background-color: var(--button-hover); 
  color: #fff;
  border-radius: 5px
}

.active {
  background-color: rgba(255, 255, 255, 0.23); 
  color: #fff;
  border-radius: 5px;
}

@media (max-width: 1068px) {
  .navbar {
    height: auto; 
    padding: 10px; 
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100vw;
  }

  .navbar-left .title {
    font-size: 18px; 
  }

  .nav-button {
    font-size: 14px; 
    margin-left: 10px; 
  }

  .navbar-right {
    flex-wrap: nowrap; 
  }
}

@media (max-width: 768px) {
  .navbar {
    height: auto; 
    padding: 0px; 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100vw;
    padding-top: 10px;
  }

  .navbar-left .title {
    font-size: 18px; 
  }

  .nav-button {
    font-size: 14px; 
    margin-left: 10px; 
  }

  .navbar-right {
    flex-wrap: nowrap; 
    padding: 0;
  }
}