body {
  overflow-x: hidden;
}

.fade-up {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-up.animate {
  opacity: 1;
  transform: translateY(0);
}

.about-container {
  background-color: #000000;
  color: white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Telegraf", sans-serif;
  overflow-x: hidden;
}

.hero-section {
  width: 100%;
  text-align: center;
  padding: 80px 20px;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #888;
  max-width: 600px;
  margin: 0 auto;
}

.purple {
  color: var(--purple);
}

.orange {
  color: var(--orange);
}

.content-section {
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
}

.mission-block {
  text-align: center;
  margin-bottom: 60px;
  padding: 0 20px;
}

.mission-block h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
}

.mission-block p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin: 60px 0;
}

.feature-card {
  background: rgba(140, 82, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(140, 82, 255, 0.2),
    0 10px 30px rgba(140, 82, 255, 0.1),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  transform-style: preserve-3d;
  perspective: 1000px;
}

.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(140, 82, 255, 0.15),
    rgba(255, 154, 104, 0.15)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px) translateZ(20px) rotateX(5deg);
  box-shadow: 0 8px 25px rgba(140, 82, 255, 0.3),
    0 15px 40px rgba(140, 82, 255, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.2),
    inset 0 -1px 1px rgba(0, 0, 0, 0.2);
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.icon {
  width: 100%;
  height: 100%;
  color: var(--orange);
}

.feature-card h3 {
  color: var(--orange);
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.feature-card p {
  color: #fff;
  line-height: 1.5;
}

.contact-section {
  margin-top: 60px;
  padding: 60px 20px;
  background: linear-gradient(
    180deg,
    rgba(140, 82, 255, 0.05) 0%,
    rgba(255, 154, 104, 0.05) 100%
  );
  border-radius: 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.footer-left {
  flex: 1;
  min-width: 300px;
}

.footer-left h2 {
  color: var(--purple);
  font-size: 2rem;
  margin-bottom: 20px;
}

.footer-left p {
  color: #888;
  line-height: 1.6;
}

.social-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 12px 24px;
  background: rgba(140, 82, 255, 0.1);
  border-radius: 50px;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: var(--purple);
  transform: translateY(-2px);
}

.link-icon {
  width: 16px;
  height: 16px;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #888;
}

.fade-up {
  opacity: 0;
  transform: translateY(30px) translateZ(0);
  transition: opacity 0.6s ease, transform 0.6s ease;
  transition-delay: var(--delay, 0s);
}

.fade-up.animate {
  opacity: 1;
  transform: translateY(0) translateZ(0);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .mission-block h2 {
    font-size: 2rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .mission-block h2 {
    font-size: 1.5rem;
  }

  .feature-card {
    padding: 20px;
  }

  .social-link {
    width: 100%;
    justify-content: center;
  }
}
