.editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #111;
  color: white;
  padding: 0px;
  position: relative;
}

.entire-screen {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 80%;
}

.everything-except-save {
  height: 100%;
  width: 100%;
}

.split-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.text-area {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #222;
  color: white;
  background: 
    linear-gradient(#222 0 0) padding-box,
    linear-gradient(to left, #8C52FF, #FF9A68) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  resize: none;
  position: relative;
  margin-top: 20px;
}

.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #555;
  color: white;
  padding: 10px 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 15px;
}

.copy-button:hover {
  background-color: var(--orange);
  transform: scale(1.1);
}

.toggle-button {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  /* Default rotation */
  background-color: #202020;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s, background-color 0.3s;
  z-index: 50;
}

.toggle-button.active {
  transform: translateX(-50%) rotate(180deg);
  /* Reverse rotation */
}

.toggle-button:hover {
  background-color: #333;
  cursor: pointer;
}

.toggle-button * {
  pointer-events: none;
}

.upload-section {
  margin-top: 20px;
  border: 2px solid var(--purple);
  /* border-image: linear-gradient(to right, #3acfd5 0%, #3a4ed5 100%) 1 ;
  border-width: 2px;
  border-style: solid; */
  /* background:  */
    /* linear-gradient(#333 0 0) padding-box, */
    /* linear-gradient(to right, #8C52FF, #FF9A68) border-box; */
  /* border: 2px solid transparent; */
  border-radius: 10px;
  background-color: #222;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-button,
.save-button,
.lock-button {
  padding: 10px 25px;
  width: auto;
  /* Set auto width instead of a percentage */
  min-width: 120px;
  /* Optional: Define a minimum width */
  background-color: var(--purple);
  color: white;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  margin: 15px 20px;
  /* Center using margin auto */
  display: block;
  /* Ensure the button is treated as a block-level element */
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
}

.upload-button:hover,
.save-button:hover,
.lock-button:hover {
  background-color: var(--orange);
  transform: scale(1.1);
}

.uploaded-files {
  max-height: 420px;
  /* Limit the height */
  overflow-y: auto;
  /* Enable vertical scroll */
  border: 1px solid #b8a3ff;
  padding: 10px;
  border-radius: 8px;
  background-color: #333;
  max-width: 300px;
  text-overflow: ellipsis;
}

.file-item {
  display: flex;
  align-items: center;
  background-color: #202020;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-icon {
  font-size: 2rem;
  margin-right: 10px;
}

.file-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.file-name {
  color: #b8a3ff;
  width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-actions {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.download-button,
.delete-button {
  background-color: #333;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.download-button {
  background-color: #009C25;
  font-weight: bold;
}

.download-button:hover {
  background-color: #009C25;
  font-weight: bold;
}

.delete-button {
  background-color: #FF0000;
  font-weight: bold;
}

.delete-button:hover {
  background-color: #ff3333;
  font-weight: bold;
}

.download-button,
.delete-button {
  /* background-color: #333; */
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.download-button:hover {
  background-color: #00b118;
  transform: scale(1.1);
  /* Slightly enlarges the button */
  box-shadow: 0 4px 8px rgba(184, 163, 255, 0.4);
  /* Adds shadow with button color */
}

.delete-button {
  background-color: #ff4d4d;
}

.delete-button:hover {
  background-color: #ff3333;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(255, 51, 51, 0.4);
  /* Adds shadow with delete color */
}

/* Optional subtle bounce animation on button press */
.download-button:active,
.delete-button:active {
  transform: scale(0.95);
  /* Slightly "bounces" down on press */
}

.Domain_name {
  text-align: center;
  margin: 20px 0;
}

.Domain_name h1 {
  font-size: 2.0rem;
  color: #fff;
}

.password-protected-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* background: linear-gradient(135deg, #6a11cb, #2575fc); */
  color: white;
}

.password-card {
  background: rgba(0, 0, 0, 0.8);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.password-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.password-description {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #d3d3d3;
}

.password-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.password-input-field {
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 70%;
  font-size: 16px;
  outline: none;
  background-color: #ffffff;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.unlock-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(135deg, #ff9a68, #ff6b6b);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.unlock-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(255, 107, 107, 0.5);
}

.password-hint {
  margin-top: 20px;
  font-size: 14px;
  color: #cccccc;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-box {
  background-color: #ccbdfc;
  padding: 20px;
  color: black;
  border-radius: 10px;
  width: 400px; 
  text-align: center;
  z-index: 1000;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.confirm-button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.confirm-button-green {
  background-color: rgb(24, 206, 0);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-button {
  background-color: grey;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.upload-spinner {
  margin-top: 10px;
  font-size: 14px;
  color: blue;
}

.bottom-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Dialog Overlay */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px); /* Optional: Adds a blur effect */
}

/* Dialog Box */
.dialog-box {
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-out; /* Optional: Fade-in animation */
}

/* Dialog Header */
.dialog-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

/* Dialog Content */
.dialog-content {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Dialog Buttons */
.dialog-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.dialog-button {
  flex: 1;
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog-button-primary {
  background-color: #007bff;
  color: white;
}

.dialog-button-primary:hover {
  background-color: #0056b3;
}

.dialog-button-secondary {
  background-color: #f8f9fa;
  color: #333;
}

.dialog-button-secondary:hover {
  background-color: #e2e6ea;
}

/* Close Icon */
.dialog-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.dialog-close:hover {
  color: #555;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



@media (max-width: 768px) {
  .split-screen {
    flex-direction: column;
  }
  .password-card {
    padding: 30px;
    max-width: 90%;
  }

  .password-title {
    font-size: 20px;
  }

  .password-description {
    font-size: 14px;
  }

  .password-input-field {
    width: 65%;
    font-size: 14px;
  }

  .unlock-button {
    padding: 8px 15px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .password-card {
    padding: 20px;
    max-width: 95%;
  }

  .password-title {
    font-size: 18px;
  }

  .password-description {
    font-size: 12px;
  }

  .password-input-field {
    width: 60%;
    font-size: 12px;
  }

  .unlock-button {
    padding: 6px 10px;
    font-size: 12px;
  }
}


@media (max-width: 768px) {
  .text-section {
    width: 100%;
    margin: 0;
  }

  .text-area {
    height: 100%;
  }

  .uploaded-files {
    max-height: 200px;
  }

  .save-button {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  .text-area {
    font-size: 14px;
    padding: 10px;
  }

  .upload-button,
  .save-button {
    font-size: 14px;
    padding: 8px 20px;
  }

  .uploaded-files {
    max-height: 150px;
  }
}