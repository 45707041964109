@font-face {
    font-family: 'TTOctosquares';
    src: url('../fonts/TT Octosquares Trial Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coco+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Telegraf&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');



:root {
    --primary-color: rgb(172, 146, 255);
    --background-color: #000000;
    --text-color: white;
    --button-hover: rgba(103, 103, 103, 0.8);
    --purple: #7f3fff;
    --orange: #FF9A68;
}

body {
    background-color: var(--background-color);
    color: white;
    font-family: 'Telegraf', sans-serif;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding: 0%;

}

.home-container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0%;
}

.title {
    font-size: 3rem;
    margin: center;
    color: var(--purple);
    font-weight: bold;
    font-family: monospace;
}

.header {
    position: absolute;
    top: 20px;
    left: 20px;
}

.subtitle {
    font-size: 50px;
    color: var(--text-color);
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 35px;
}

.purple {
    display: inline;
    color: var(--purple);
}

.orange {
    display: inline;
    color: var(--orange);
}

.white {
    color: white;
}

.platform-text {
    font-family: 'Telegraf', sans-serif;
    font-size: 23.6px;
    color: white;
}

.hassle-free {
    color: #FF9A68;
}

.form-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
}

.dropdown-group {
    margin-bottom: 20px;
    margin-top: 54px;
}

.form-label {
    display: block;
    margin-bottom: 70px;
    margin-top: -15px;
    color: white;
}

.input-group {
    display: flex;
    align-items: center;
    width: 88%;
    background-color: var(--purple);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(255, 255, 255, 0.5);
    border: solid 2px var(--purple);
}

.input-group input {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: white;
    border-radius: 50px;
}

.input-group-text {
    background-color: var(--purple);
    border: none;
    color: rgb(255, 255, 255);
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(255, 255, 255, 0.5);
}

.password-input-group {
    display: flex;
}

.password-input-group input {
    background-color: white;
    border-radius: 10px 0 0 10px;
}

.password-input-group button {
    background-color: white;
    border-radius: 0 10px 10px 0;
    border: white;
    display: flex;
    justify-content: end;
}

.password-input-group button:hover {
    background-color: white;
    color: var(--purple)
}

.form-control {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: var(--text-color);
    color: var(--background-color);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.button-set {
    background-color: var(--purple);
    color: black;
    width: 300px;
    padding: 10px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-set:hover {
    background-color: var(--purple);
    color: white;
    border: solid 3px var(--purple);
    scale: 1.05;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4), inset 0 -3px 6px rgba(255, 255, 255, 0.6);
}

.dropdown-toggle {
    background-color: var(--purple);
    border: none;
    color: black;
    width: 50%;
}

.dropdown-menu {
    background-color: white;
    color: black;
}

.dropdown-item {
    color: black;
    background-color: white;
}

.dropdown-item:hover {
    background-color: var(--purple);
    color: black;
}

.selected-value {
    display: block;
    margin-top: 10px;
    font-weight: bold;
}

.buttons {
    display: flex;
    flex-direction: row;
}

.submit-button {
    margin-top: 20px;
    background-color: var(--purple);
    padding: 15px 60px;
    color: var(--white);
    border-radius: 15px;
    cursor: pointer;
    width: 10%;
    border: solid 2px var(--purple);
    font-size: 1.2rem;
    font-weight: bold;
    transition: all 0.3s ease;
    width: 221.1px;
    height: 53.3px;
    font-family: 'TTOctosquares', sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(255, 255, 255, 0.5);
}

.arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: white;
    margin-left: 10px;
}

.arrow-icon {
    width: 15px;
    height: 15px;
}

.submit-button:hover {
    background-color: var(--purple);
    color: white;
    border: solid 3px var(--purple);
    scale: 1.05;
}

.btn {
    padding: 0.8rem;
    font-weight: 600;
    width: 223.9px;
    height: 44.8px;
}

.copy-div {
    width: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.error-message {
    color: red;
}

.button-set {
    background-color: var(--purple);
    color: rgb(233, 233, 233);
    width: 222.9px;
    height: 44.8px;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(255, 255, 255, 0.5);
    border: solid 2px var(--purple);
    text-align: center;
    line-height: 16.8px;
}

.icon-container {
    display: inline-block;
    position: relative;
}

.tooltip-text-Set-Password {
    visibility: hidden;
    background-color: #3B3B3B;
    color: #fff;
    text-align: centre;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 1rem;
    left: 105%;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 0.3s;
    width: 229.3px;
}

.icon-container:hover .tooltip-text-Set-Password,
.icon-container:hover .tooltip-text-Set-Time {
    visibility: visible;
    opacity: 1;
}

.tooltip-text-Set-Time {
    visibility: hidden;
    background-color: #3B3B3B;
    color: #fff;
    text-align: centre;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 1rem;
    right: 105%;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 0.3s;
    width: 229.3px;
}

.btnctn1,
.btnctn2 {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.btnctn2 {
    flex-direction: row-reverse;
}

.pass-btn {
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media (max-width: 480px) {
    .home-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 13px;
    }

    .logo {
        width: 35px;
        height: 30px;
        margin-right: 0%;
    }

    .button-container {
        flex-direction: column;
        gap: 0px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .title {
        font-size: 1.5rem;
    }

    .subtitle {
        font-size: 1.2rem;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .dropdown-toggle {
        width: 70%;
    }

    .button-set {
        width: 80%;
        ;
        padding: 8px;
        font-size: 0.9rem;
    }

    .submit-button {
        width: 70%;
        padding: 10px 30px;
        font-size: 0.9rem;
        margin-top: 50px;
    }

    .btn {
        padding: 0.3rem;
        font-size: 0.8rem;
    }

    .input-group,
    .password-input-group {
        width: 75%;
    }

    .input-group input {
        font-size: 0.9rem;
        width: 150%;
    }

    .button-container {
        flex-direction: column;
        gap: 10px;
    }

    .icon-container {
        display: inline-block;
        position: relative;
    }

    .dropdown-group {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .form-group {
        margin-bottom: 50px;
    }

    .btnctn2 {
        flex-direction: row;
    }

}

@media (max-width: 768px) {
    .home-container {
        width: 94vw;
    }

    .title {
        font-size: 1.8rem;
    }

    .subtitle {
        font-size: 1.6rem;
    }

    .dropdown-toggle {
        width: 60%;
    }

    .button-set {
        width: 180px;
    }

    .submit-button {
        width: 40%;
        padding: 10px 40px;
        font-size: 1rem;
        margin-left: 30px;
    }

    .btn {
        padding: 0.4rem;
        font-size: 0.9rem;
    }

    .input-group input {
        font-size: 0.9rem;
    }
}

@media (max-width: 1068px) {
    .home-container {
        width: 100%;
        margin-top: 80px;
        padding: 10px;
    }

    .title {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1.8rem;
    }

    .dropdown-toggle {
        width: 50%;
    }

    .button-set {
        width: 200px;
    }

    .btn {
        padding: 0.5rem;
    }

    .tooltip-text-Set-Password {
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        font-size: 1rem;
        left: -200%;
        top: 50%;
        opacity: 0;
        transform: translateY(20%);
        transition: opacity 0.3s;
        width: 150.3px;
    }

    .tooltip-text-Set-Time {
        padding: 0px;
        position: absolute;
        z-index: 1;
        font-size: 1rem;
        right: -450%;
        top: 50%;
        opacity: 0;
        transform: translateY(-120%);
        transition: opacity 0.3s;
        width: 175.3px;
    }

    .form-group .input-group {
        width: 90vw;
    }

    .form-label {
        margin-bottom: 30px;
    }

    .button-container {
        margin-left: -20px;
    }
}